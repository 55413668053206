<template>
    <EditableArea
        :value="spaceLongName"
        :apiURL="'/spaces/' + this.$route.params.sid"
        successMessage="Space name successfully updated!"
        editAttr="long_id"
        fontClass="title font-weight-bold"
        editAttrFullName="space name"
        defaultValue="Space name not available"
        color="white"
        :showEditIcon="isSpaceAdmin"
        :id="parseInt($route.params.sid, 10)"
        :finally="
            () => {
                this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
            }
        "
    ></EditableArea>
</template>

<script>
import { mapGetters } from 'vuex'
const EditableArea = () => import('@/components/EditableArea')

export default {
    name: 'TheSpaceEditableNameField',
    components: {
        EditableArea
    },
    computed: {
        spaceLongName() {
            return this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
        },
        ...mapGetters('spaceStore', ['isSpaceAdmin'])
    }
}
</script>
