var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditableArea", {
    attrs: {
      value: _vm.spaceLongName,
      apiURL: "/spaces/" + this.$route.params.sid,
      successMessage: "Space name successfully updated!",
      editAttr: "long_id",
      fontClass: "title font-weight-bold",
      editAttrFullName: "space name",
      defaultValue: "Space name not available",
      color: "white",
      showEditIcon: _vm.isSpaceAdmin,
      id: parseInt(_vm.$route.params.sid, 10),
      finally: function() {
        this$1.$store.dispatch(
          "orgStore/fetchOrgSpaces",
          this$1.$route.params.oid
        )
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }